<style lang="scss">
  .map-container{
    height: 350px;
  }
</style>
<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="6">
          <b-form-group label="Número de Gabinete">
            <b-form-input id="numberGabinets" v-model="numberGabinets" placeholder="Ingrese el número de Gabinete a instalar"/>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="." style="color:#FFFFFF">
            <b-button variant="primarySpore" @click="getCabinets" v-b-tooltip.hover.top title="Comenzar instalación de Gabinete">
              <feather-icon icon="LinkIcon"/>
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="statusForm">
      <b-row>
        <b-col md="5">
          <b-row>
            <b-col md="12">
              <b-form-group label="Módulo (serie spore)">
                <b-form-input id="numberSpore" v-model="numberSpore" :disabled="showMeters"/>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group label="Modelo de Gabinete">
                <v-select name="cabinetType" :options="cabinetType" v-model="cabinet.type" :reduce="v => v.value" value-field="value" text-field="label" :clearable="true"/>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group label="División">
                <v-select :options="opcDivision" v-model="division" :disabled="true"/>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group label="Agencias">
                <v-select :options="opcAgencies" v-model="agencies" :disabled="showMeters"/>
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-1">
              <b-form-group label="Instalador">
                <v-select :options="opcInstallerUser" v-model="installer" :disabled="showMeters"/>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="7">
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col md="6">
                  <b-form-group label="Calle">
                    <b-form-input id="street" v-model="street" :disabled="showMeters"/>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group label="Número Exterior">
                    <b-form-input id="numExt" v-model="numExt" :disabled="showMeters"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" class="mt-1">
                  <b-form-group label="Colonia">
                    <b-form-input id="suburb" v-model="suburb" :disabled="showMeters"/>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="mt-1">
                  <b-form-group label="Código Postal">
                    <b-form-input id="postalCode" v-model="postalCode" :disabled="showMeters"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" class="mt-1">
                  <b-form-group label="Localidad">
                    <b-form-input id="location" v-model="location" :disabled="showMeters"/>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="mt-1">
                  <b-form-group label="Municipio">
                    <b-form-input id="municipality" v-model="municipality" :disabled="showMeters"/>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" class="mt-1">
                  <b-form-group label="Estado">
                    <b-form-input id="state" v-model="state" :disabled="showMeters"/>
                  </b-form-group>
                </b-col>
                <b-col md="6" class="mt-1">

                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="10" style="margin-top: 30px">
              <b-form-group label="Latitud y Longitud (Puede mover el Pin del mapa a la ubicación deseada o escibir la latitud y longitud en la caja de texto)" label-size="sm">
                <b-form-input id="latlong" v-model="latlong" />
              </b-form-group>
            </b-col>
            <b-col md="2" class="mt-1">
              <b-form-group style="color:#FFFFFF" >
                <b-button style="margin-top: 30px" variant="primarySpore" @click="sendLatlong" >
                  Localizar
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="12" class="mt-2">
              <div class="map-container" style="border: 1px solid #ccc; border-radius: 5px;">
                <l-map :center="center" :zoom="zoom" :options="{attributionControl: false, zoomControl: false}">
                  <l-control-zoom position="bottomright" :options="{ zoomInTitle: 'Acercar', zoomOutTitle: 'Alejar' }"/>
                  <l-control-attribution position="bottomright" prefix="Spore Cloud"></l-control-attribution>
                  <l-tile-layer :attribution="attribution" :url="url">
                  </l-tile-layer>
                  <l-marker :draggable="true" :lat-lng="marker" @dragend="changeMarker" ref="position_marker">
                    <l-icon :icon-anchor="staticAnchor">
                      <b-img
                        rounded
                        :src="require(`@/assets/images/markers/LPoint.png`)"
                        height="50"
                      />
                    </l-icon>
                  </l-marker>
                </l-map>
              </div>
            </b-col>
        <b-col
          v-if="!showMeters"
          md="12"
          class="mt-3 text-right"
        >
          <b-button
            class=""
            variant="primarySpore"
            @click="putRegister"
          >
            <feather-icon
              icon="ArrowRightCircleIcon"
            />
            siguiente
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    <b-row v-if="showMeters">
      <b-col md="12">
        <b-card>
          <b-card-header>
            <b-card-title>Medidores</b-card-title>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                md="12"
                class="text-right"
              >
                <b-button
                  v-if="responseMetering.length !== 0"
                  class="mb-2"
                  variant="primarySpore"
                  @click="postRegisterAll"
                >
                  <feather-icon
                    icon="RefreshCcwIcon"
                  />
                  Sincronizar todo
                </b-button>
              </b-col>
              <b-col md="12" class="text-center" v-if="responseMetering.length === 0">
                <h5>No hay medidores en el Gabinete</h5>
              </b-col>
            </b-row>
            <b-table
              v-if="responseMetering.length > 0"
              class="position-relative"
              hover
              responsive
              primary-key="id"
              show-emptysomething
              :items="responseMetering"
              :fields="tableColumnsMetering"
            >
              <template #cell(Action)="row">
                <b-form-checkbox
                  v-if="!isEmpty(row.item.registedAt)"
                  v-model="row.detailsShowing"
                  plain
                  class="vs-checkbox-con"
                  @change="row.toggleDetails"
                >
                  <span class="vs-checkbox">
                    <span class="vs-checkbox--check">
                      <i class="vs-icon feather icon-check" />
                    </span>
                  </span>
                  <span class="vs-label">{{ row.detailsShowing ? 'Ocultar' : 'Mostrar' }}</span>
                </b-form-checkbox>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-1">
                    <b-col
                      md="9"
                      class="mb-1"
                    >
                      <b-row class="mb-1">
                        <b-col
                          md="6"
                          class="mb-1"
                        >
                          <feather-icon class="align-text-top" icon="CloudLightningIcon" size="25" /> &nbsp;
                          <strong>Corriente: </strong>&nbsp;{{ decimal(row.item.electricCurrent, 3) }} A
                        </b-col>
                        <b-col
                          md="6"
                          class="mb-1"
                        >
                          <feather-icon class="align-text-top" icon="BatteryChargingIcon" size="25" /> &nbsp;
                          <strong>Almacenamiento de energía: </strong>&nbsp;{{ decimal(row.item.storedEnergy, 3) }} Kw/h
                        </b-col>
                        <b-col
                          md="6"
                          class="mb-1"
                        >
                          <feather-icon class="align-text-top" icon="ActivityIcon" size="25" /> &nbsp;
                          <strong>Frecuencia: </strong>&nbsp;{{ decimal(row.item.frecuency, 2) }} Hz
                        </b-col>
                        <b-col
                          md="6"
                          class="mb-1"
                        >
                          <feather-icon class="align-text-top" icon="ZapIcon" size="25" /> &nbsp;
                          <strong>Voltaje: </strong>&nbsp;{{ decimal(row.item.voltage, 2) }} V
                        </b-col>
                        <b-col
                          md="6"
                          class="mb-1"
                        >
                          <feather-icon class="align-text-top" v-if="parseInt(row.item.status) === 1" icon="ZapIcon" size="25" />&nbsp;
                          <feather-icon class="align-text-top" v-if="parseInt(row.item.status) === 0" icon="ZapOffIcon" size="25"/>&nbsp;
                          <strong>Estado: </strong>&nbsp;{{parseInt(row.item.status) === 1 ? 'con servicio' : 'sin servicio' }}
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col
                      md="3"
                      class="mb-1 text-righ"
                    >
                      <qrcode-vue :value="getValue(row.item.number.replace(/^(0)+/, ''), row.item.registedAt, row.item.transmitedAt)" size="200" level="H" />
                    </b-col>
                  </b-row>

                  <b-button
                    variant="outline-info"
                    @click="row.toggleDetails"
                  >
                    Ocultar Detalle
                  </b-button>
                </b-card>
              </template>

              <template #cell(Number)="data">
                <div class="text-nowrap selectable" v-if="data.item.number">
                  <b-avatar
                    v-if="data.item.number"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/hash.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ data.item.number }}</span>
                </div>
              </template>
              <template #cell(ElectricCurrent)="data">
                <div class="text-nowrap selectable" v-if="data.item.electricCurrent">
                  <b-avatar
                    v-if="data.item.electricCurrent"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/energy.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ decimal(data.item.electricCurrent, 3) }}</span>
                </div>
              </template>
              <template #cell(Position)="data">
                <div class="text-nowrap selectable" v-if="data.item.position">
                  <b-avatar
                    v-if="data.item.position"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/alias.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ data.item.position }}</span>
                </div>
              </template>
              <template #cell(Status)="data">
                <div class="text-nowrap selectable">
                  <b-avatar
                    v-if="data.item.status"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    :variant="parseInt(data.item.status) === 1 ? 'success' : 'secondary'"
                  >
                    <feather-icon class="align-text-top" v-if="parseInt(data.item.status) === 1" icon="ZapIcon" size="18" />
                    <feather-icon class="align-text-top" v-if="parseInt(data.item.status) === 0" icon="ZapOffIcon" size="18"/>
                  </b-avatar>
                  <b>
                    <span class="align-text-top text-capitalize ml-1" :class="parseInt(data.item.status) === 1 ? 'text-success' : ''">{{parseInt(data.item.status) === 1 ? 'con servicio' : 'sin servicio' }}</span>
                  </b>
                </div>
              </template>
              <template #cell(Transmited)="data">
                <div class="text-nowrap selectable" v-if="data.item.transmitedAt">
                  <b-avatar
                    v-if="data.item.transmitedAt"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="transparent"
                  >
                    <b-img :src="require(`@/assets/images/spore-icons/fecha.svg`)" height="20"/>
                  </b-avatar>
                  <span class="align-text-top text-capitalize ml-1">{{ format(data.item.transmitedAt) }}</span>
                </div>
              </template>
              <template #cell(Register)="data">
                <div class="text-center selectable">
                  <b-avatar
                    v-if="data.item.registedAt"
                    size="32"
                    class="avatar-border-2 box-shadow-1"
                    variant="success"
                  >
                    <feather-icon class="align-text-top" icon="CheckCircleIcon" size="18" />
                  </b-avatar>
                  <b-button
                    v-if="isEmpty(data.item.registedAt)"
                    variant="warning"
                    @click="postRegisterOne(data.item.uuid)"
                  >
                    <feather-icon
                      icon="RefreshCcwIcon"
                    />
                  </b-button>
                </div>
              </template>
            </b-table>
            <b-col
              v-if="responseMetering.length > 0"
              md="12"
              class="mt-3 text-right"
            >
              <b-button
                class=""
                variant="primarySpore"
                @click="exit()"
              >
                <feather-icon
                  icon="LogOutIcon"
                />
                Terminar
              </b-button>
            </b-col>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BRow, BCol, BFormGroup, BFormInput, BButton, BImg, BTable, BAvatar, BFormCheckbox, VBTooltip
} from 'bootstrap-vue'
import request from '@/libs/request/index'
import vSelect from 'vue-select'
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LControlAttribution,
  LControlZoom
} from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'
import { formatDateLanguages, empty, decimales } from '@/libs/tools/helpers'
import QrcodeVue from 'qrcode.vue'

/* global L:true */
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LControlAttribution,
    BImg,
    BCardHeader,
    BCardBody,
    BAvatar,
    BTable,
    BCardTitle,
    BFormCheckbox,
    QrcodeVue,
    LControlZoom
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      cabinet: {},
      cabinetType: [
        { value: 'P', label: 'Protecsa' },
        { value: 'N', label: 'Nansen' },
        { value: 'S', label: 'Siemens' }
      ],
      opcAgencies: [],
      opcDivision: [],
      opcInstallerUser: [],
      agencies: [],
      division: [],
      installer: [],
      responseMetering: [],
      response: [],
      numberSpore: '',
      numberGabinets: '',
      gabinetsUuid: '',
      latlong: '',
      street: '',
      numExt: '',
      suburb: '',
      postalCode: '',
      location: '',
      municipality: '',
      state: '',
      zoom: 5,
      center: L.latLng(20.6242631, -100.9927305),
      url: 'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png',
      attribution: '',
      marker: L.latLng(20.6242631, -100.9927305),
      staticAnchor: [12, 27],
      statusForm: false,
      showMeters: false,
      tableColumnsMetering: [
        { key: 'Action', label: 'SiNaMed' },
        { key: 'Number', label: 'Número', sortable: true },
        { key: 'ElectricCurrent', label: 'Lectura', sortable: true },
        { key: 'Position', label: 'Posición', sortable: true },
        { key: 'Status', label: 'Estatus', sortable: true },
        { key: 'Transmited', label: 'Última transmisión', sortable: true },
        { key: 'Register', label: 'Registrado', sortable: true }
      ]
    }
  },
  methods: {
    decimal (val, n) {
      return decimales(val, n)
    },
    getValue (numero, register, transmited) {
      return `Número de medidor: ${numero} | Fecha de registro: ${register} | Fecha de transmisión: ${transmited}`
    },
    exit (uuid) {
      this.$router.push('/gabinets')
    },
    changeMarker () {
      const location = this.$refs.position_marker.mapObject.getLatLng()
      this.latlong = `${location.lat}, ${location.lng}`
    },
    isEmpty (val) {
      return empty(val)
    },
    sendLatlong () {
      const lat = this.latlong.split(',')[0]
      const long = this.latlong.split(',')[1]
      this.zoom = 15
      this.marker = L.latLng(lat, long)
      this.center = L.latLng(lat, long)
    },
    format (date) {
      return formatDateLanguages(date, 'hour')
    },
    async getCatDivision () {
      this.response = []
      const params = {
        url: '/divisions/list/all',
        method: 'GET'
      }
      await request(params).then(data => {
        for (let index = 0; index < data.data.data.length; index++) {
          this.opcDivision.push({
            label: data.data.data[index].name,
            title: data.data.data[index].name,
            value: data.data.data[index].uuid
          })
        }
      })
    },
    async getCatAgencies () {
      this.response = []
      const params = {
        url: '/agencies/list/all',
        method: 'GET'
      }
      await request(params).then(data => {
        for (let index = 0; index < data.data.data.length; index++) {
          this.opcAgencies.push({
            label: data.data.data[index].name,
            title: data.data.data[index].name,
            value: data.data.data[index].uuid
          })
        }
      })
    },
    async getCabinets () {
      if (empty(this.numberGabinets)) {
        this.$swal({
          title: '¡Atención!',
          text: 'Colocar el número de gabinete',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } else {
        const params = {
          url: `/cabinets/${this.numberGabinets}`,
          method: 'GET'
        }
        await request(params).then(data => {
          this.statusForm = true
          this.numberSpore = data.data.data.cfeDevice.serie
          this.agencies = this.opcAgencies.filter(el => { return el.label === data.data.data.cfeDevice.cfeAgency.name })[0]
          this.division = this.opcDivision.filter(el => { return el.value === '148f4686-4576-407c-9e79-2f57d16445f5'/* data.data.data.divisionUuid */ })
          this.installer = this.opcInstallerUser.filter(el => { return el.value === data.data.data.userInstalledUuid })[0]
          this.responseMetering = data.data.data.cfeMeters
          this.gabinetsUuid = !empty(data.data.data.cfeMeters) ? data.data.data.cfeMeters[0].cabinetUuid : ''
          if (data.data.data.latlong) {
            this.latlong = data.data.data.latlong
            const lat = data.data.data.latlong.split(',')[0]
            const long = data.data.data.latlong.split(',')[1]
            this.zoom = 18
            this.marker = L.latLng(lat, long)
            this.center = L.latLng(lat, long)
          }
          if (!empty(this.numberGabinets) && !empty(this.agencies) && !empty(this.division) && !empty(this.installer) && !empty(this.latlong)) {
            this.showMeters = true
          }
        }).catch(e => {
          if (e.response.status === 404) {
            this.statusForm = true
            this.showMeters = false
            this.numberSpore = ''
            this.agencies = []
            this.division = this.opcDivision.filter(el => { return el.value === '148f4686-4576-407c-9e79-2f57d16445f5'/* data.data.data.divisionUuid */ })
            this.installer = []
            this.responseMetering = []
            this.latlong = ''
          }
        })
      }
    },
    async putRegister () {
      if (empty(this.numberGabinets) || empty(this.cabinet.type) || empty(this.agencies) || empty(this.division) || empty(this.installer) || empty(this.latlong)) {
        this.$swal({
          title: '¡Atención!',
          text: 'Completar los datos del formulario',
          icon: 'warning',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      } else {
        const params = {
          url: '/devices/install/desktop',
          method: 'PUT',
          customMessages: true,
          params: {
            userUuid: this.installer.value,
            serieDevice: this.numberSpore,
            cabinetNumber: this.numberGabinets,
            agencyUuid: this.agencies.value,
            divisionUuid: this.division[0].value,
            latlong: this.latlong,
            type: this.cabinet.type,
            street: this.street,
            numExt: this.numExt,
            suburb: this.suburb,
            postalCode: this.postalCode,
            location: this.location,
            municipality: this.municipality,
            state: this.state
          }
        }
        await request(params).then(data => {
          this.showMeters = true
        }).catch(e => {
          if (e.response.status === 409) {
            this.$swal({
              title: '¡Atención!',
              text: 'Gabinete ya existente, favor de revisar número',
              icon: 'warning',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        })
      }
    },
    async postRegisterAll () {
      this.$swal({
        title: '¿Estas seguro?',
        text: 'Se sincronizará todos los medidores con SiNaMed',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `/cabinets/${this.gabinetsUuid}`,
            method: 'POST'
          }
          await request(params).then(data => {
            this.getCabinets()
          })
        }
      })
    },
    postRegisterOne (uuid) {
      this.$swal({
        title: '¿Estas seguro?',
        text: 'Se sincronizará medidor con SiNaMed',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `/meters/${uuid}`,
            method: 'POST'
          }
          await request(params).then(data => {
            this.getCabinets()
          })
        }
      })
    },
    async getCatInstallerUser () {
      this.response = []
      const params = {
        url: '/users/installers/list',
        method: 'GET',
        params: {
          divisionUuid: this.$store.state.session.AppActiveUser().division.uuid
        }
      }
      await request(params).then(data => {
        for (let index = 0; index < data.data.data.length; index++) {
          this.opcInstallerUser.push({
            label: `${data.data.data[index].lastName} ${data.data.data[index].firstName}`,
            title: `${data.data.data[index].lastName} ${data.data.data[index].firstName}`,
            value: data.data.data[index].uuid
          })
        }
      })
    }
  },
  created () {
    this.getCatAgencies()
    this.getCatDivision()
    this.getCatInstallerUser()
  }
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
